import classNames from "classnames"
import React, { useCallback, useState } from "react"

import { Color } from "../../../../constants/V2/color"
import { useIsBreakpoint } from "../../../../utils/V2/screen"
import CircledIconButton from "../../../elements/V2/Buttons/CircledIconButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../../elements/V2/Typography"
import Video from "../../../elements/V2/Video"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import {
  getBackgroundColorClass,
  getGradientFromColorClass,
  getGradientToColorClass,
} from "@utils/V2/color"

type Props = {
  primaryBackgroundColor?: Color
  secondaryBackgroundColor?: Color
  textColor: Color.White | Color.Charcoal
  superscriptText: string
  titleText: string
  descriptionText: string
  videoUrl?: string
  imageUrl: string
  imageAlt: string
  imageRounded: boolean
  backgroundImage: boolean
  buttonColor?: Color
  buttonLink?: string
}

const Content = ({
  primaryBackgroundColor,
  secondaryBackgroundColor,
  textColor,
  superscriptText,
  titleText,
  descriptionText,
  videoUrl,
  imageUrl,
  imageAlt,
  imageRounded,
  backgroundImage,
  buttonColor,
  buttonLink,
  ...props
}: Props) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)
  const isDesktop = useIsBreakpoint("desktop")
  const [videoContainerHover, setVideoContainerHover] = useState(false)

  const onMouseEnter = useCallback(() => {
    setVideoContainerHover(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setVideoContainerHover(false)
  }, [])

  return (
    <div
      ref={animationRefCallback}
      onMouseEnter={videoUrl ? onMouseEnter : undefined}
      onMouseLeave={videoUrl ? onMouseLeave : undefined}
      className={classNames(
        !secondaryBackgroundColor && primaryBackgroundColor && !videoUrl
          ? getBackgroundColorClass(primaryBackgroundColor)
          : secondaryBackgroundColor && primaryBackgroundColor && !videoUrl
            ? [
                "bg-gradient-to-br",
                getGradientFromColorClass(primaryBackgroundColor),
                getGradientToColorClass(secondaryBackgroundColor),
              ]
            : "",
        "rounded-lg-v2 relative col-span-full flex grid-rows-[max-content_1fr] flex-col justify-between overflow-hidden sm-v2:col-span-2 md-v2:col-span-3 lg-v2:col-span-4"
      )}
      {...props}
    >
      <div
        className={classNames(
          "z-10 flex flex-col gap-16-v2 px-20-v2 py-24-v2 md-v2:px-24-v2 md-v2:py-32-v2 lg-v2:px-32-v2 lg-v2:py-48-v2",
          { absolute: backgroundImage || videoUrl }
        )}
      >
        <Typography
          font="grotesk"
          weight="book"
          size="subscript-lg"
          color={textColor}
          text={superscriptText}
        />
        <Typography
          font="grotesk"
          weight="book"
          size="lead-lg"
          color={textColor}
          text={titleText}
        />
        <Typography
          font="grotesk"
          weight="book"
          size="body-lg"
          color={textColor}
          text={descriptionText}
        />
      </div>

      {videoUrl ? (
        <>
          <div className="absolute inset-0 flex items-center justify-center">
            <GatsbyStoryblokImage
              aspectRatio={backgroundImage ? "3:4" : "4:3"}
              image={imageUrl}
              alt={imageAlt}
              width={495}
              rounded={imageRounded}
            />
          </div>
          <Video
            video={videoUrl}
            coverImage={imageUrl}
            ImageAlt={imageAlt}
            aspectRatio="3:4"
            playbackMode={isDesktop ? "hover" : "autoplay"}
            videoContainerHover={videoContainerHover}
            rounded={true}
          />
        </>
      ) : (
        <GatsbyStoryblokImage
          aspectRatio={backgroundImage ? "3:4" : "4:3"}
          image={imageUrl}
          alt={imageAlt || "Benefit image"}
          width={495}
          rounded={imageRounded}
        />
      )}
      {buttonColor && buttonLink && (
        <div className="absolute bottom-20-v2 right-20-v2 md-v2:bottom-24-v2 md-v2:right-24-v2 lg-v2:bottom-32-v2 lg-v2:right-32-v2">
          <CircledIconButton color={buttonColor} linkUrl={buttonLink} />
        </div>
      )}
    </div>
  )
}

export default Content
